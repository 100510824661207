import { Language } from './language.model';

export interface ITranslation {
    id: number;
    translationFileJson: string;
}

export class Translation extends Language implements ITranslation {
    id: number;
    translationFileJson: string;

    constructor(id: number, name: string, code: string, json: string) {
        super(name, code);
        this.id = id;
        this.translationFileJson = json;
    }
}
