import { Language } from '@/models/translations/language.model';
import { Translation } from '@/models/translations/translation.model';
import { BaseApiService } from '../base.service';

export class TranslationService extends BaseApiService {
    constructor() {
        super('');
    }

    public async GetAvailableLanguagesAsync(): Promise<Language[]> {
        const response = await fetch('api/LanguagesLookup');
        if (response) {
            return await response.json();
        }

        return [];
    }

    public async GetTranslationAsync(
        languageCode: string
    ): Promise<Translation> {
        const response = await fetch(
            `api/TranslationLookup?languageCode=${languageCode}`
        );
        if (response) {
            return response.json();
        }

        return new Translation(0, '', '', '');
    }
}

export const translationService: TranslationService = new TranslationService();
