import { Constants } from '@/constants';
import { InjectionKey } from 'vue';
import { createStore, Store } from 'vuex';

export interface State {
    selectedLanguage: string;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
    state: {
        selectedLanguage: ''
    },
    mutations: {
        initialiseStore: (state) => {
            const chosenLanguage = localStorage.getItem(
                Constants.LocalStorageKeys.chosenLanguage
            );
            if (chosenLanguage) {
                state.selectedLanguage = localStorage.getItem(
                    Constants.LocalStorageKeys.chosenLanguage
                )!;
            }
        },
        setLanguage: (state: any, language: string) => {
            localStorage.setItem(
                Constants.LocalStorageKeys.chosenLanguage,
                language
            );
            state.selectedLanguage = language;
        }
    }
});
