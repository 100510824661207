import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store, key } from './store/store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import useVuelidate from '@vuelidate/core';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { faCaretRight, faRedo } from '@fortawesome/free-solid-svg-icons';
import VueGoogleMaps from '@fawmi/vue-google-maps';

library.add(
    faPencil,
    faCheckCircle,
    faTimes,
    faExclamationTriangle,
    faRepeat,
    faCaretRight,
    faRedo
);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { GlobalizationSupport } from './globalizationSupport';
import { Constants } from './constants';

const gs = new GlobalizationSupport();
gs.getLocales().then(() => {
    const i18n = gs.create();
    gs.loadLocale(Constants.defaultLanguage).then(() => {
        gs.setLocale(Constants.defaultLanguage)
        .then(() => {
            createApp(App)
                .component('font-awesome-icon', FontAwesomeIcon)
                .use(store, key)
                .use(useVuelidate)
                .use(router)
                .use(i18n)
                .use(VueGoogleMaps, {
                    load: {
                        key: 'AIzaSyCXbgsID_aj-pAadZOJlyru5C4Qk_D2UkU'
                    }
                })
                .provide('globalizationSupport', gs)
                .mount('#app');
        });
    });
});
