import { IDealershipSummaryModel } from '@/models/configuration/dealerships/dealership-summary.model';
import {
    GoogleDriveTimeResults,
    GoogleDriveTimeResultsModel
} from '@/models/google-drive-time-results.model';
import { Position } from '@/models/google-map-marker.model';
import { BaseApiService } from '../base.service';

export class GoogleMapsApiService extends BaseApiService {
    constructor() {
        super('');
    }

    readonly apiKey = 'AIzaSyCXbgsID_aj-pAadZOJlyru5C4Qk_D2UkU';

    public async getAddressGeocode(address: string): Promise<Position> {
        let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=';

        url += this.encodeString(address);
        url += '&key=' + this.apiKey;

        const result = await this.http
            .get(url)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));

        return new Position(
            result.results[0].geometry.location.lat,
            result.results[0].geometry.location.lng
        );
    }

    public async getDriveTime(
        address: string,
        dealers: IDealershipSummaryModel[],
        languageCode: string
    ): Promise<GoogleDriveTimeResultsModel> {
        const api = new google.maps.DistanceMatrixService();
        return api
            .getDistanceMatrix({
                origins: dealers.map(
                    (x) => new google.maps.LatLng(x.latitude, x.longitude)
                ),
                destinations: [address],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.IMPERIAL
            })
            .then(
                (result) =>
                    new GoogleDriveTimeResults(
                        result.destinationAddresses,
                        result.originAddresses,
                        result.rows,
                        ''
                    )
            );
    }

    public async getDirections(
        origin: Position,
        destination: Position
    ): Promise<google.maps.DirectionsResult> {
        const directionsService = new google.maps.DirectionsService();

        return directionsService
            .route({
                origin: { lat: origin.lat, lng: origin.lng },
                destination: { lat: destination.lat, lng: destination.lng },
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.IMPERIAL
            })
            .then((result) => result);
    }

    private encodeString(value: string): string {
        return value.replace(',', ' ').replace(' ', '%20');
    }
}
export const googleMapsApiService: GoogleMapsApiService =
    new GoogleMapsApiService();
