
import RecallFormComponent from '@/components/recall-form.component.vue';
import SelectLanguageComponent from '@/components/select-language.component.vue';
import Modal from 'bootstrap/js/dist/modal';
import { GlobalizationSupport } from '@/globalizationSupport';
import { Options, Vue } from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { Store, useStore } from 'vuex';
import { key, State } from '../store/store';

@Options({ components: { RecallFormComponent, SelectLanguageComponent } })
export default class Home extends Vue {
    @Inject() globalizationSupport!: GlobalizationSupport;

    showIntroText = true;
    selectLanguageModalDialogId = 'select-language-modal';
    languageSelectionDialog?: Modal;
    store?: Store<State>;

    mounted() {
        this.store = useStore(key);
        this.languageSelectionDialog = new Modal(document.getElementById(this.selectLanguageModalDialogId)!, {});
        if (!this.store.state.selectedLanguage) {
            this.languageSelectionDialog.show();
        }
        else {
            let code = this.globalizationSupport.allLocales.find(x => x.key == this.store?.state.selectedLanguage);
            if(!code) {
                this.store.state.selectedLanguage = 'en-GB';
            }

            this.globalizationSupport.setLocale(this.store.state.selectedLanguage);   
        }
    }

    onFormSubmission(success: boolean) {
        if (success) {
            this.showIntroText = false;
        }
    }

    onLanguageSelected() {
        if (this.languageSelectionDialog) {
            this.languageSelectionDialog.hide();
        }
    }
}
