import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container py-5" }
const _hoisted_2 = { class: "row mb-3" }
const _hoisted_3 = { class: "col-12 col-lg-6" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = {
  class: "accordion",
  id: "accordionExample"
}
const _hoisted_7 = ["id"]
const _hoisted_8 = ["data-bs-target", "aria-controls", "id"]
const _hoisted_9 = ["id", "aria-labelledby"]
const _hoisted_10 = { class: "accordion-body fs-5" }
const _hoisted_11 = { class: "accordion-item" }
const _hoisted_12 = {
  class: "accordion-header",
  id: "headingMulti"
}
const _hoisted_13 = {
  class: "accordion-button collapsed fs-3",
  type: "button",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#collapseMulti",
  "aria-expanded": "false",
  "aria-controls": "collapseMulti",
  id: "faqs-accordian-multipart"
}
const _hoisted_14 = {
  id: "collapseMulti",
  class: "accordion-collapse collapse",
  "aria-labelledby": "headingMulti",
  "data-bs-parent": "#accordionExample"
}
const _hoisted_15 = { class: "accordion-body fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('faqScreen.siteHeading')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getQuestionCount(), (index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "accordion-item",
              key: index
            }, [
              _createElementVNode("h2", {
                class: "accordion-header",
                id: 'heading' + index
              }, [
                _createElementVNode("button", {
                  class: "accordion-button collapsed fs-3",
                  type: "button",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": '#collapse' + index,
                  "aria-expanded": "false",
                  "aria-controls": 'collapse' + index,
                  id: 'faqs-accordian-' + index
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t(
                                            'faqScreen.questions[' +
                                                (index - 1) +
                                                '].question'
                                        )), 1)
                ], 8, _hoisted_8)
              ], 8, _hoisted_7),
              _createElementVNode("div", {
                id: 'collapse' + index,
                class: "accordion-collapse collapse",
                "aria-labelledby": 'heading' + index,
                "data-bs-parent": "#accordionExample"
              }, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t(
                                        'faqScreen.questions[' +
                                            (index - 1) +
                                            '].answer'
                                    )), 1)
              ], 8, _hoisted_9)
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h2", _hoisted_12, [
              _createElementVNode("button", _hoisted_13, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t(
                                            'faqScreen.multiAnswerQuestion.question'
                                        )), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createTextVNode(_toDisplayString(_ctx.$t(
                                        'faqScreen.multiAnswerQuestion.answerPart1'
                                    )) + " ", 1),
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, _toDisplayString(_ctx.$t(
                                                'faqScreen.multiAnswerQuestion.answerPart2'
                                            )), 1),
                  _createElementVNode("li", null, _toDisplayString(_ctx.$t(
                                                'faqScreen.multiAnswerQuestion.answerPart3'
                                            )), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}