export class GoogleMapMarkerModel {
    key: number;
    position: Position;

    constructor(key: number, lat: number, lng: number) {
        this.key = key;
        this.position = new Position(lat, lng);
    }
}

export class Position {
    lat: number;
    lng: number;

    constructor(lat: number, lng: number) {
        this.lat = lat;
        this.lng = lng;
    }
}
