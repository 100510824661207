import { CustomerAddressModel } from './customer-address.model';
import { CustomerContactDetailsModel } from './customer-contact-details.model';
import { CustomerNameModel } from './customer-name.model';
import { CustomerVehicleDetailsModel } from './customer-vehicle-details.model';

export class FormSubmissionModel {
    name: CustomerNameModel = new CustomerNameModel();
    dealershipId?: number;
    details?: string;
    address: CustomerAddressModel = new CustomerAddressModel();
    contactDetails = new CustomerContactDetailsModel();
    vehicleDetails = new CustomerVehicleDetailsModel();
}
