import IValidationResult from '@/models/validation/validation-result.model';
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';

export function isAxiosError(value: any): value is AxiosError {
    return typeof value?.response === 'object';
}

export abstract class BaseApiService {
    protected readonly http: AxiosInstance;

    ApplicationJson = 'application/json';

    protected constructor(protected readonly path: string) {
        let baseURL = '';

        if (path) {
            baseURL += path;
        }

        this.http = axios.create({
            baseURL,
            headers: {
                Accept: this.ApplicationJson
            }
        });
    }

    protected handleResponse<T>(response: AxiosResponse<T>): T {
        return response.data;
    }

    protected handleError(error: AxiosError): void {
        const reference: string = error.response?.data['reference'];
        const responseType: string = error.response?.data['type'];
        if (responseType === 'Validation') {
            const validationErrors: IValidationResult[] =
                error.response?.data['errors'];
            let errorHtml = `
                <div class="acl-toast-title">
                    <span>One or more validation errors occurred (reference ${reference}).</span>
                </div>`;

            if (validationErrors) {
                errorHtml += `<ul>`;
                validationErrors.forEach((error) => {
                    error.messages.forEach((message) => {
                        errorHtml += `<li>${message}</li>`;
                    });

                    errorHtml += `</ul>`;
                });
            }

            throw errorHtml;
        }

        throw 'A problem occurred while processing your request. Please contact support if the problem persists.';
    }
}
