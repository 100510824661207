import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('thankYouScreen.heading')), 1),
    (_ctx.dealershipSelected)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('thankYouScreen.paragraphWhenDealershipSelected')), 1))
      : _createCommentVNode("", true),
    (!_ctx.dealershipSelected)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('thankYouScreen.paragraphWhenDealershipNotSelected')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      class: "btn btn-primary float-end mt-3",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startOver && _ctx.startOver(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t('thankYouScreen.checkAnotherVehicleButtonText')) + " ", 1),
      _createElementVNode("span", null, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fas', 'caret-right'],
          class: "ms-1"
        })
      ])
    ])
  ]))
}