export class Constants {
    static Store = class {
        static Mutations = class {
            static initialiseStore = 'initialiseStore';
            static setLanguage = 'setLanguage';
        };
    };

    static LocalStorageKeys = class {
        static chosenLanguage = 'chosenLanguage';
    };

    static DataAnnotations = class {
        static defaultNameLength = 255;
        static defaultStringLength = 512;
        static postcodeLength = 15;
        static countryCodeLength = 2;
    };

    static Events = class {
        static languageSelected = 'language-selected';
    };

    static Regex = class {
        // source: https://gis.stackexchange.com/questions/297229/where-i-can-find-a-good-regexp-to-extract-sector-district-and-area-from-a-uk-po
        static ukPostcode = new RegExp(
            '^(((([A-Z][A-Z]{0,1})[0-9][A-Z0-9]{0,1}) {0,}[0-9])[A-Z]{2})$'
        );
        static internationalPhoneNumbers = new RegExp(
            '^(\\+|0)(?:[0-9]?){6,14}[0-9]$'
        );
    };

    static defaultLanguage = 'en-GB';
}
