
import { Options, Vue } from 'vue-class-component';
import { useStore } from 'vuex';
import { Constants } from './constants';
import { key } from './store/store';
import Nav from '@/components/navigation/nav.component.vue';

@Options({ components: { Nav } })
export default class App extends Vue {
    beforeCreate() {
        const store = useStore(key);
        store.commit(Constants.Store.Mutations.initialiseStore);
    }
}
