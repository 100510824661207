
import { Options, Vue } from 'vue-class-component';
import useValidate, { Validation, ValidationArgs } from '@vuelidate/core';
import {
    required,
    email,
    maxLength,
    helpers,
    requiredIf,
createI18nMessage
} from '@vuelidate/validators';
import { Ref } from 'vue';
import { Constants } from '@/constants';
import ErrorMessage from '@/components/fields/error-message.component.vue';
import Thanks from '@/components/thanks.component.vue';
import { VueRecaptcha } from 'vue-recaptcha';
import { Inject, Watch } from 'vue-property-decorator';
import {
    DealershipSummaryModel,
    IDealershipSummaryModel
} from '@/models/configuration/dealerships/dealership-summary.model';

import { FormSubmissionModel } from '@/models/form-submission.model';
import { IVinValidationResultModel } from '@/models/vin-validation-result.model';
import { ICountryModel } from '@/models/country.model';
import { GoogleMapMarkerModel } from '@/models/google-map-marker.model';

import { googleMapsApiService } from '@/services/googleMaps/googleMaps.service';
import { GoogleDriveTimeResultsModel } from '@/models/google-drive-time-results.model';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { GlobalizationSupport } from '@/globalizationSupport';
import { DistanceUnit } from '@/models/distance-unit.enum';

@Options({
    components: { ErrorMessage, Thanks, VueRecaptcha },
    validations() {
        return {
            model: {
                name: {
                    forename: {
                        isRequired: helpers.withMessage('recallForm.validationRequired.required', required),
                        maxLength: helpers.withMessage('validationMessage.length225', maxLength(Constants.DataAnnotations.defaultNameLength)) 
                    },
                    surname: {
                        isRequired: helpers.withMessage('recallForm.validationRequired.required', required),
                        maxLength: helpers.withMessage('validationMessage.length225', maxLength(Constants.DataAnnotations.defaultNameLength))
                    }
                },
                address: {
                    line1: {
                        isRequired: helpers.withMessage('recallForm.validationRequired.required', required),
                        maxLength: helpers.withMessage('validationMessage.length225', maxLength(Constants.DataAnnotations.defaultNameLength))
                    },
                    line2: {
                        maxLength: helpers.withMessage('validationMessage.length225', maxLength(Constants.DataAnnotations.defaultNameLength))
                    },
                    line3: {
                        maxLength: helpers.withMessage('validationMessage.length225', maxLength(Constants.DataAnnotations.defaultNameLength))
                    },
                    city: {
                        isRequired: helpers.withMessage('recallForm.validationRequired.required', required),
                        maxLength: helpers.withMessage('validationMessage.length225', maxLength(Constants.DataAnnotations.defaultNameLength))
                    },
                    postcode: {
                        isRequired: helpers.withMessage('recallForm.validationRequired.required', required),
                        maxLength: helpers.withMessage('validationMessage.length15', maxLength(Constants.DataAnnotations.postcodeLength))
                    },
                    country: {
                        isRequired: helpers.withMessage('recallForm.validationRequired.required', required)
                    }
                },
                dealershipId: {
                    validDealership: helpers.withMessage('recallForm.dealershipInput.validationMessage',
                        (value: number) => value >= 0
                    )
                },
                details: {
                    requiredIfNoDealership: helpers.withMessage('recallForm.validationRequired.required',
                        requiredIf(() => this.model.dealershipId === 0)
                    )
                },
                contactDetails: {
                    email: {
                        email,
                        maxLength: helpers.withMessage('recallForm.validationRequired.invalid', maxLength(Constants.DataAnnotations.defaultNameLength))
                    },
                    telephone: {
                        isRequired: helpers.withMessage('recallForm.validationRequired.required', required),
                        telephone: helpers.withMessage('recallForm.validationRequired.invalid', helpers.regex(Constants.Regex.internationalPhoneNumbers))
                    }
                },
                vehicleDetails: {
                    registrationNumber: {},
                    vin: {
                        isRequired: helpers.withMessage('recallForm.validationRequired.required',required),
                        vin: helpers.withMessage('recallForm.vinInput.validationMessage',
                            (value: string) =>
                                value.length == 11 || value.length == 17
                        )
                    }
                }
            }
        };
    }
})
export default class RecallFormComponent extends Vue {
    submitted = false;
    recaptchaVerified = false;
    showRecaptchaMessage = false;
    dealers: IDealershipSummaryModel[] = [];
    countries: ICountryModel[] = [];
    privacyPolicyLink = '';
    countryChoice = 0;
    countryDistanceUnit = DistanceUnit.Miles;

    hasValidatedVin = false;
    vinValid = false;
    validatingVin = false;
    showVinMessage = false;

    noSuitableOptionDisabled = false;
    testEnv = process.env.NODE_ENV != 'production';
    dealershipDropdownEnabled = false;
    dealershipSelected = false;
    submitting = false;

    model = new FormSubmissionModel();
    v$!: Ref<Validation<ValidationArgs, unknown>>;
    markers: GoogleMapMarkerModel[] = [];
    center = new GoogleMapMarkerModel(1, 1.0, 1.0);

    @Inject() globalizationSupport!: GlobalizationSupport;

    async mounted() {
        await this.getCountries();
    }

    async created() {
        this.v$ = useValidate();
    }

    @Watch('model.address.postcode')
    async onPostcodeChanged(value: string, oldValue: string) {
        if (value !== oldValue && value.length >= 4) {
            await this.addressLocationLookup();
            await this.dealershipLookup();
        }
        else if(value.length < 5) {
            this.dealers = [];
            this.markers = [];
            this.model.dealershipId = undefined;
            this.dealershipSelected = false;
        }

        this.dealershipDropdownEnabled = this.dealers.length != 0;
    }

    @Watch('model.address.country')
    async onCountryChanged(value: string, oldValue: string) {
        if (value !== oldValue) {
            this.onCountryChange(value);
            await this.addressLocationLookup();
            await this.dealershipLookup();
        }
    }

    @Watch('model.dealershipId')
    async dealershipChanged(value: number, oldValue: number) {
        if(value !== undefined) {
            var dealer = this.dealers.find((d) => d.id == value);
            this.dealershipSelected = dealer?.id != 0;
        }
    }

    async onVinSubmit() {
        this.showVinMessage = false;
        if (
            this.model.vehicleDetails.vin.length === 11 ||
            this.model.vehicleDetails.vin.length === 17
        ) {
            await this.checkVinValidity(true);
            return;
        }

        this.showVinMessage = true;
    }

    private async checkVinValidity(resetState: boolean) {
        if (resetState) {
            this.hasValidatedVin = false;
            this.vinValid = false;
            this.validatingVin = true;
        }

        const response = await fetch(
            `api/VinValidation?vin=${this.model.vehicleDetails.vin}`
        );

        if (response) {
            await response
                .json()
                .then((result: IVinValidationResultModel) => {
                    this.vinValid = result.isValid;
                })
                .catch(() => {
                    this.vinValid = false;
                })
                .finally(() => {
                    if (resetState) {
                        this.hasValidatedVin = true;
                        this.validatingVin = false;
                    }
                });
        }
    }

    retryVin(): void {
        this.hasValidatedVin = false;
        this.vinValid = false;
        this.model = new FormSubmissionModel();
    }

    getDealershipLabel(dealer: DealershipSummaryModel): string {
        let distanceUnit = this.countryDistanceUnit === 1 ? 'recallForm.kilometers' : 'recallForm.miles';
        return dealer.id === 0
            ? dealer.name
            : `${dealer.name} - ${dealer.distance.toFixed(1)} ${this.$t(
                distanceUnit
            )} (${dealer.postcode})` +
            ` - ${this.$t('driveTime.label')}: ${dealer.drivetime}`;
    }

    async getCountries() {
        const response = await fetch('api/CountriesLookup').catch(() => {
            //fail silently.
        });

        if (response) {
            this.countries = await response.json();
        }
    }

    onCountryChange(selectedCountry: string) {
        var country = this.countries.find((x) => x.name == selectedCountry);
        this.countryChoice = country?.id || 0;
        this.countryDistanceUnit = country?.distanceUnit || DistanceUnit.Miles;
        if (country) {
            this.noSuitableOptionDisabled = country.noSuitableOptionDisabled;
            this.privacyPolicyLink = country.privacyPolicy;
            let dealerIndex = this.dealers.findIndex((x) => x.id == 0);
            if (country.noSuitableOptionDisabled) {
                if (dealerIndex > -1) {
                    this.dealers.splice(dealerIndex, 1);
                }
            } else if (dealerIndex === -1) {
                let noSuitableOption = new DealershipSummaryModel();
                noSuitableOption.id = 0;
                noSuitableOption.name = this.$t('recallForm.dealershipInput.noSuitableOptionText');

                this.dealers.push(noSuitableOption);
            }
        }
    }

    async submit() {
        this.v$?.value.$validate();
        if (this.v$.value.model.$error) {
            return;
        }

        if (!this.recaptchaVerified) {
            this.showRecaptchaMessage = true;
            return;
        }

        this.submitting = true;

        await this.checkVinValidity(false).then(() => {
            if (this.vinValid) {
                fetch(`api/FormSubmission`, {
                    method: 'POST',
                    body: JSON.stringify(this.model)
                })
                    .then(() => {
                        this.submitted = true;
                        this.$emit('formSubmitted', true);
                    })
                    .finally(() => {
                        this.submitting = false;
                    });
            }
        });
    }

    onRecaptchaVerified(response: string): void {
        if (response) {
            this.showRecaptchaMessage = false;
            this.recaptchaVerified = true;
        }
    }

    onRecaptchaExpired(): void {
        this.showRecaptchaMessage = false;
        this.recaptchaVerified = false;
    }

    public FormatLatLong(lat?: number, long?: number): string {
        if (lat == undefined || long == undefined) {
            return '';
        }

        return `${lat},${long}`;
    }

    public encodeString(value: string): string {
        return value.replace(',', ' ').replace(' ', '%20');
    }

    private async dealershipLookup() {
        const response = await fetch(
            `api/DealershipLookup?longitude=${this.center.position.lng}&latitude=${this.center.position.lat}&countryid=${this.countryChoice}`
        ).catch(() => {
            // fail silently - the fallback is to use the default option.
        });

        if (response) {
            this.dealers = await response.json();
            if (!this.noSuitableOptionDisabled) {
                let noSuitableOption = new DealershipSummaryModel();
                noSuitableOption.id = 0;
                noSuitableOption.name = this.$t('recallForm.dealershipInput.noSuitableOptionText');
                this.dealers.push(noSuitableOption);
            }

            var driveTimes = await this.getDriveTimes();
            this.markers = [this.center];
            this.model.dealershipId = undefined;
            this.dealershipSelected = false;

            this.dealers.forEach((dealer, i) => {
                if (driveTimes.rows[i].elements[0].duration != undefined) {
                    dealer.drivetime =
                        driveTimes.rows[i].elements[0].duration.text;
                    this.markers.push(
                        new GoogleMapMarkerModel(
                            dealer.id,
                            dealer.latitude,
                            dealer.longitude
                        )
                    );
                }
            });
        }
    }

    private async getDriveTimes(): Promise<GoogleDriveTimeResultsModel> {
        let model = await googleMapsApiService.getDriveTime(
            this.FormatLatLong(
                this.center.position.lat,
                this.center.position.lng
            ),
            this.dealers,
            this.globalizationSupport.globalInstance.value.locale.value
        );

        return model;
    }

    private async addressLocationLookup() {
        let geocode = await googleMapsApiService.getAddressGeocode(
            this.model.address.ToString(' ')
        );

        if (geocode) {
            let marker = new GoogleMapMarkerModel(1, geocode.lat, geocode.lng);
            this.center = marker;
            this.markers = [];
            this.markers.push(marker);
        }
    }
}
