
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export default class ErrorMessage extends Vue {
    @Prop({ default: false, required: false })
    dealershipSelected!: boolean;

    startOver(): void {
        window.location.href = '/';
    }
}
