export class CustomerAddressModel {
    line1 = '';
    line2 = '';
    line3 = '';
    city = '';
    postcode = '';
    country = '';

    ToString(lineSeperator: string): string {
        let address = '';

        if (this.line1 != '') {
            if (address != '') {
                address += lineSeperator;
            }

            address += this.line1;
        }

        if (this.line2 != '') {
            if (address != '') {
                address += lineSeperator;
            }

            address += this.line2;
        }

        if (this.line3 != '') {
            if (address != '') {
                address += lineSeperator;
            }

            address += this.line3;
        }

        if (this.city != '') {
            if (address != '') {
                address += lineSeperator;
            }

            address += this.city;
        }

        if (this.postcode != '') {
            if (address != '') {
                address += lineSeperator;
            }

            address += this.postcode;
        }

        if (this.country != '') {
            if (address != '') {
                address += lineSeperator;
            }

            address += this.country;
        }

        return address;
    }
}
