import { computed, WritableComputedRef } from 'vue';
import { Composer, createI18n, I18n } from 'vue-i18n';
import { Constants } from './constants';
import { KeyValueModel } from './models/key-value.model';
import { Language } from './models/translations/language.model';
import { Translation } from './models/translations/translation.model';
import { translationService } from './services/translations/translations.service';

export class GlobalizationSupport {
    // List of all locales.
    allLocales: KeyValueModel<string, string>[] = [];
    messages: Translation[] = [];

    globalInstance!: WritableComputedRef<
        Composer<any, any, any, string, never, string>
    >;

    create(): I18n<any, any, any, string, false> {
        const i18n = createI18n({
            legacy: false,
            locale: Constants.defaultLanguage,
            fallbackLocale: Constants.defaultLanguage,
            Messages: this.messages.map((x) => x.translationFileJson)
        });

        this.globalInstance = computed(() => i18n.global);
        return i18n;
    }

    async getLocales() {
        return translationService
            .GetAvailableLanguagesAsync()
            .then((response: Language[]) => {
                this.allLocales = response
                    .map((x) => new KeyValueModel(x.code, x.name))
                    .sort((v1, v2) => {
                        return v1.value.localeCompare(v2.value);
                    });
            });
    }

    // Set new locale.
    async setLocale(locale: string) {
        if (!this.globalInstance.value.availableLocales.includes(locale)) {
            await this.loadLocale(locale).then(() => {
                this.globalInstance.value.locale.value = locale;
            });
        } else {
            this.globalInstance.value.locale.value = locale;
        }
    }

    // Fetch locale.
    async loadLocale(locale: string) {
        return translationService
            .GetTranslationAsync(locale)
            .then((response: Translation) => {
                if (response.translationFileJson != '') {
                    this.messages.push(response);
                    this.globalInstance.value.setLocaleMessage(
                        locale,
                        JSON.parse(response.translationFileJson)
                    );
                }
            });
    }
}
