import { or } from '@vuelidate/validators';

export interface DriveTimeDistance {
    text: string;
    value: number;
}

export interface DriveTimeDuration {
    text: string;
    value: number;
}

export interface DriveTimeElement {
    distance: DriveTimeDistance;
    duration: DriveTimeDuration;
    status: string;
}

export interface DriveTimeRow {
    elements: DriveTimeElement[];
}

export interface GoogleDriveTimeResultsModel {
    destination_addresses: string[];
    origin_addresses: string[];
    rows: DriveTimeRow[];
    status: string;
}

export class GoogleDriveTimeResults implements GoogleDriveTimeResultsModel {
    destination_addresses: string[];
    origin_addresses: string[];
    rows: DriveTimeRow[];
    status: string;

    constructor(
        destinations: string[],
        origins: string[],
        rows: DriveTimeRow[],
        status: string
    ) {
        this.destination_addresses = destinations;
        this.origin_addresses = origins;
        this.rows = rows;
        this.status = status;
    }
}
