
import { Vue } from 'vue-class-component';
import { Inject, Prop, Watch } from 'vue-property-decorator';
import { key, State } from '../store/store';
import { Constants } from '@/constants';
import { Store, useStore } from 'vuex';
import { GlobalizationSupport } from '@/globalizationSupport';

export default class SelectLanguageComponent extends Vue {
    @Inject() globalizationSupport!: GlobalizationSupport;
    @Prop({ default: 'select-language-modal', required: true })
    modalId!: string;

    store?: Store<State>;
    chosenLanguage = '';

    @Watch('globalizationSupport.globalInstance.value.locale.value')
    OnlocaleChanged(){
        this.chosenLanguage = this.globalizationSupport.globalInstance.value.locale.value;
    }

    mounted() {
        this.chosenLanguage = this.globalizationSupport.globalInstance.value.locale.value;
        this.store = useStore(key);
    }

    selectLanguage() {
        this.store?.commit(
            Constants.Store.Mutations.setLanguage,
            this.chosenLanguage
        );
        this.globalizationSupport.setLocale(this.chosenLanguage);
        this.$emit(Constants.Events.languageSelected);
    }
}
