
import { Options, Vue } from 'vue-class-component';

export default class Faqs extends Vue {
    getQuestionCount() {
        let i = 0;

        while (i >= 0) {
            let key = 'faqScreen.questions[' + i + '].question';
            let value = this.$t(key);

            if (key === value) {
                break;
            }

            i++;
        }

        return i;
    }
}
