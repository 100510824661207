export interface IDealershipSummaryModel {
    id: number;
    name: string;
    postcode: string;
    distance: number;
    latitude: number;
    longitude: number;
    drivetime: string;
}

export class DealershipSummaryModel implements IDealershipSummaryModel {
    id = 0;
    name = '';
    postcode = '';
    distance = 0;
    latitude = 1.0;
    longitude = 1.0;
    drivetime = '';
}
